<template>
  <footer id="footer">
    <div class="container py-4 py-md-5 mt-4">
      <div class="row">
        <div class="col-md-4 mb-3">
          <h3 class="mb-2 mb-md-3">Tentang Kami</h3>
          <p class="mb-0">
            {{ tentang }}
          </p>
        </div>

        <div class="col-md-4 mb-3">
          <h3 class="mb-2 mb-md-3">Link Tautan</h3>
          <div class="mb-2" @click="handleClick()">
            <i class="bi-arrow-return-right me-2"></i>Perjuangan Guntur
          </div>
        </div>
        <div class="col-md-4 mb-3">
          <h3 class="mb-2 mb-md-3">Sosial Media</h3>
          <span>
            <i class="bi-instagram me-3 h2"></i>
            <i class="bi-facebook me-3 h2"></i>
          </span>
        </div>
      </div>

      <div class="row text-center mt-4">
        <div class="col">
          <div>Copyright &copy; {{ new Date().getFullYear() }} All Right Reserved</div>
          <div>
            Developed By
            <a href="https://ridoananda.com" class="developer">Syafrizal Siagan</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: ["tentang"],
  methods: {
    handleClick() {
      return (window.location.href = "https://e-learning.ridoananda.com");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variable.scss";

footer {
  background-color: $primaryColor;
  color: #ffffff;
  h3 {
    color: #ffffff !important;
  }
  .developer {
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
  }
}
</style>
